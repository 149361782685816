import axiosLib from 'axios';
import { ApiAmount, ApiDonationHistoryRecord, ApiProfile } from '../models/api';
import { auth } from '@/./firebase-app';
import { Charity } from '@/models/store';

const axios = axiosLib.create({ baseURL: window.config.baseUrlServer });

export default class SocialService {
  async getUserProfile(): Promise<ApiProfile | null> {
    try {
      const accessToken = await this.getIdToken();
      if (!accessToken) {
        return null;
      }

      const response = await axios.get<ApiProfile>('/customer/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    } catch (err) {
      if (err.response && (err.response.status === 400 || err.response.status === 401 || err.response.status === 404)) {
        // subscription (and therefore user) not yet created
        return null;
      }
      throw err;
    }
  }

  /**
   * @param dob format YYYY-MM-DD
   */
  async setUserProfile(firstName: string, lastName: string, dob: string, nationality: string) {
    const accessToken = await this.getIdToken();
    if (!accessToken) {
      return null;
    }

    const response = await axios.post(
      '/customer/profile',
      {
        firstName,
        lastName,
        dob,
        nationality,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }

  async checkout(amount: ApiAmount, charities: Charity[]) {
    const email = auth.currentUser?.email;
    const accessToken = await this.getIdToken();
    const redirectUrl = `${window.config.baseUrl}/dank-je-wel`;

    if (!email || !accessToken) {
      console.log('Error while checking out: no email or accessToken');
      return null;
    }

    try {
      const result = await axios.post(
        '/subscription/checkout',
        {
          email,
          amount,
          charities,
          redirectUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return result.data;
    } catch (err) {
      console.error('Error while checking out: ', err);
      return null;
    }
  }

  async getDonationHistory(): Promise<ApiDonationHistoryRecord[] | null> {
    try {
      const accessToken = await this.getIdToken();
      if (!accessToken) {
        return null;
      }

      const response = await axios.get<ApiDonationHistoryRecord[]>('/subscription/history', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        // subscription not yet created
        return null;
      }
      throw err;
    }
  }

  async cancelSubscription() {
    const accessToken = await this.getIdToken();
    if (!accessToken) {
      return null;
    }
    return axios.get<undefined>('/subscription/cancel', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  async getIdToken() {
    if (!auth.currentUser) {
      return null;
    }

    return auth.currentUser.getIdToken();
  }

  /**
   * Admin only methods
   */
  async adminGetAdmins() {
    const accessToken = await this.getIdToken();
    const response = await axios.get('/admin', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  async adminGetExport() {
    const accessToken = await this.getIdToken();
    const res = await axios.get('/admin/export', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  }
}
