import * as contentful from 'contentful';
import { ContentfulClientApi } from 'contentful';
import { IContentPage, INgo } from '@/models/contentful';

export default class ContentfulService {
  private client: ContentfulClientApi;

  constructor() {
    this.client = contentful.createClient({
      space: window.config.contentfulSpace,
      accessToken: window.config.contentfulToken,
    });
  }

  async fetchNgoBySlug(slug: string): Promise<INgo | null> {
    const data = await this.fetchPageBySlug<INgo>('ngo', slug);
    return data;
  }

  async fetchContentPageBySlug(slug: string): Promise<IContentPage | null> {
    const data = await this.fetchPageBySlug<IContentPage>('contentPage', slug);
    return data;
  }

  async fetchNGOs(): Promise<INgo[] | null> {
    try {
      const data = await this.client.getEntries({
        content_type: 'ngo',
        order: "fields.volgorde,fields.name"
      });
      return data.items as INgo[];
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private async fetchPageBySlug<T>(contentType: string, slug: string) {
    try {
      const data = await this.client.getEntries({
        // tslint:disable-next-line
        content_type: contentType,
        'fields.slug': slug,
        include: 1,
      });
      return (data.items[0] as unknown) as T;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
